import React, { PureComponent } from "react";
import $ from "jquery";
import Apitablecomponent from "./apitablecomponent";
import ScrollspyNav from "react-scrollspy-nav";
import {
  screeningAPIReqMsg,
  screeningAPIResMsg,
  authresponse,
  pythonResAuth,
  DiffkindReportDownloadAPI,
} from "./apidocsconstants";
import Right from "../../assets/images/Right Arrow.svg";
import cogoToast from "cogo-toast";
import JSONPretty from "react-json-pretty";
import { GetApiDocs } from "../../apis/home-api";
class apidoc extends PureComponent {
  constructor() {
    super();
    this.state = {
      data: {},
    };
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var initTopPosition = $("#possticky").offset().top;
    $(window).scroll(function () {
      if ($(window).scrollTop() + 70 > initTopPosition)
        $("#possticky").css({ position: "sticky", top: "80px" });
      else $("#possticky").css({ position: "", top: "", width: "" });
    });
    GetApiDocs().then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data });
      }
      //
    });
  }
  copydata = (val) => {
    navigator.clipboard.writeText(val);
    const { hide } = cogoToast.success("Copied To Clipboard", {
      toastContainerID: "NewToast",
      position: "top-right",
      heading: "Success",
      hideAfter: 5,
      onClick: () => {
        hide();
      },
    });
  };
  handleShow = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      var headerOffset = 50;
      var elementPosition = target.getBoundingClientRect().top + headerOffset;
      var offsetPosition = elementPosition;
      window.scrollBy({
        top: offsetPosition - 110,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <div id="NewPageLayout">
        <div className="container-fluid px-3 mt-100" id="apidocs">
          <div className="row py-3 px-4">
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <h1 className="new_heading  mb-3">API Documentation</h1>
            </div>
            <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
              <span className="font-color-grey font-size-14 mt-3">
                Transact Comply offers a transaction monitoring platform with an
                API-style output tailored for developer documentation. It
                enables users to analyze and evaluate transactions to detect
                suspicious activity using flexible scenarios and a comprehensive
                rule library. This document provides an overview of the API,
                authentication process, details on Transact Comply API usage,
                sample request and response structures, and specifications of
                data fields.
              </span>
            </div>
          </div>

          <div className="home-page-conatiner mt-5">
            {/* start page title section */}
            <div className="px-4">
              {/* end page title section */}

              {/* start post content section */}
              <div className="row pb-3">
                <div className="col-lg-3 col-md-3 col-sm-12 mb-4 mb-md-0 col-wd-2 pos-sticky ">
                  <nav
                    className="sidebar borderS border-bottom-left"
                    id="possticky"
                  >
                    <ScrollspyNav
                      scrollTargetIds={[
                        "download-section",
                        "authentication",
                        "PrescreeningAPI",
                        "data-fields",
                        "UpdateRuleAPI",
                        "UpdateRuleStatus",
                        "Response-codes",
                      ]}
                      activeNavClass="activeMenu"
                      scrollDuration="10"
                    >
                      <>
                        <a
                          data-to-scrollspy-id="download-section"
                          onClick={(e) => this.handleShow(e)}
                          href="#download-section"
                          className="nav-link scrollto activeMenu d-flex justify-content-between border-top-left"
                        >
                          API Overview
                          <img src={Right} className="RightArrow float-right" />
                        </a>
                      </>
                      <a
                        data-to-scrollspy-id="authentication"
                        onClick={(e) => this.handleShow(e)}
                        href="#authentication"
                        className={
                          "nav-link scrollto d-flex justify-content-between"
                        }
                      >
                        Authentication
                        <img src={Right} className="RightArrow float-right" />
                      </a>

                      <a
                        data-to-scrollspy-id="PrescreeningAPI"
                        onClick={(e) => this.handleShow(e)}
                        href="#PrescreeningAPI"
                        className={
                          "nav-link scrollto d-flex justify-content-between"
                        }
                      >
                        Transact Comply API
                        <img src={Right} className="RightArrow float-right" />
                      </a>
                      <a
                        data-to-scrollspy-id="data-fields"
                        onClick={(e) => this.handleShow(e)}
                        href="#data-fields"
                        className={
                          "nav-link scrollto d-flex justify-content-between"
                        }
                      >
                        Data Field Details
                        <img src={Right} className="RightArrow float-right" />
                      </a>
                      <a
                        data-to-scrollspy-id="UpdateRuleAPI"
                        onClick={(e) => this.handleShow(e)}
                        href="#UpdateRuleAPI"
                        className={
                          "nav-link scrollto d-flex justify-content-between"
                        }
                      >
                         Rule Configuration API
                        <img src={Right} className="RightArrow float-right" />
                      </a>
                      <a
                        data-to-scrollspy-id="UpdateRuleStatus"
                        onClick={(e) => this.handleShow(e)}
                        href="#UpdateRuleStatus"
                        className={
                          "nav-link scrollto d-flex justify-content-between"
                        }
                      >
                       Rule Status API
                        <img src={Right} className="RightArrow float-right" />
                      </a>
                      <a
                        data-to-scrollspy-id="data-fields"
                        onClick={(e) => this.handleShow(e)}
                        href="#Response-codes"
                        className={
                          "nav-link scrollto d-flex justify-content-between border-bottom-left"
                        }
                      >
                        HTTP Response Code Description
                        <img src={Right} className="RightArrow float-right" />
                      </a>
                    </ScrollspyNav>
                  </nav>
                </div>

                <div className="col-lg-9 col-md-9 col-sm-12 mb-4 mb-md-0 col-wd-2 white-bg px-0 borderleftright border-top-left">
                  <section className="section-pb-50" id="download-section">
                    <div className="mtr-heading py-2 borderbottomtop mb-4 border-top-left">
                      API Overview
                    </div>
                    <div className="row">
                      <div className="col-lg-12 px-4">
                        <div className="vi-info-p">
                          <p>
                            The Transact Comply API is designed to seamlessly
                            integrate with third-party platforms, providing
                            comprehensive transaction monitoring functionalities
                            across various use cases.
                          </p>
                          <p>
                            The aim is to enable transaction monitoring
                            functions in partners platform where monitoring can
                            be done for single or multiple entities (via batch
                            upload) for individuals and organizations..
                          </p>
                          <p>Please contact us for API related information.</p>
                          <p>
                            API displayed in the documentation is for
                            representation purpose.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section-pb-50" id="authentication">
                    <div className="mtr-heading py-2 borderbottomtop mb-4">
                      Authentication
                    </div>
                    <div className="row">
                      <div className="col-12 px-4">
                        <div className="vi-info-p">
                          <p>
                            It follows standard OAuth2.0 architecture for
                            authentication and authorization. OAuth is a
                            delegated authorization framework for REST/APIs. It
                            enables apps to obtain limited access (scopes) to a
                            user's datawithout giving away a user's password. It
                            decouples authentication from authorization and
                            supports multiple use cases addressing different
                            device capabilities.{" "}
                          </p>
                          <div className=" my-3 apiborderbox col-lg-12 col-md-12 p-3">
                            {" "}
                            <p>
                              <strong>Base URL</strong>:{" "}
                              {process.env.REACT_APP_BACKEND_API_BASE_URL}{" "}
                              <br />
                              <br />
                              <strong>End point</strong> : TOKEN <br />
                              <br />
                              <strong>Method</strong>: POST <br />
                              <br />
                            </p>
                          </div>{" "}
                        </div>
                        <div className="row  ">
                          <div className="col-lg-12 col-md-12">
                            <div className="mtr-heading-New font-size-14">
                              Example Request
                            </div>
                            <br />
                            <pre className="req h10rem">
                              <i
                                class="fa fa-clone float-right copy"
                                onClick={() =>
                                  this.copydata(
                                    JSON.stringify(
                                      {
                                        username: "abc@123",
                                        password: "123456",
                                      },
                                      null,
                                      2
                                    )
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                              <br />

                              <code className="test">
                                {JSON.stringify(
                                  {
                                    username: "abc@123",
                                    password: "123456",
                                  },
                                  null,
                                  2
                                )}
                              </code>
                            </pre>
                            <div className="mtr-heading-New font-size-14">
                              Example Response:
                            </div>
                            <br />
                            <pre className="req h10rem d-flex">
                              <i
                                class="fa fa-clone float-right copy"
                                onClick={() =>
                                  this.copydata(
                                    JSON.stringify(authresponse, null, 2)
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                              <code className="mt-4">
                                {JSON.stringify(authresponse, null, 2)}
                              </code>
                            </pre>
                          </div>
                          {/* <div className='col-lg-6 col-md-12 ' >
                                    <ApiMultiLanguageComponent python={pythonResAuth} json={JSON.stringify(authresponse, null, 2)} />
                                 </div> */}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="section-pb-50" id="PrescreeningAPI">
                    <div className="mtr-heading py-2 borderbottomtop mb-4">
                      Transact Comply API
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 px-4">
                        <div className="vi-info-p  ">
                          {/* <p>Prescreening API provided risk score for various like PEP, Sanctions, AML etc within seconds to third party
                             platform via secured, stable, and highly available API. All the above checks are configurable for third
                             party platforms. </p> */}
                          <div className="my-3 apiborderbox col-lg-12 col-md-12 p-3">
                            <p>
                              <strong>Base URL</strong>:{" "}
                              {process.env.REACT_APP_BACKEND_API_BASE_URL}{" "}
                              <br />
                              <br />
                              <strong>End point</strong> :{" "}
                              {this.state.data?.transactComplyAPI?.endpoint}{" "}
                              <br />
                              <br />
                              <strong>Method</strong>: POST <br />
                              <br />
                              <strong>Authentication</strong>: access token
                              recieved from authentication API
                            </p>
                          </div>
                        </div>
                        <div className="mtr-heading-New font-size-14">
                          Example Request
                        </div>
                        <br />
                        <pre className="req NewOverFlowScroll">
                          <i
                            class="fa fa-clone float-right copy"
                            onClick={() =>
                              this.copydata(
                                this.state.data?.transactComplyAPI
                                  ?.exampleRequest
                              )
                            }
                            aria-hidden="true"
                          ></i>
                          <code>
                            {this.state.data?.transactComplyAPI
                              ?.exampleRequest && (
                              <JSONPretty
                                id="json-pretty"
                                data={
                                  this.state.data?.transactComplyAPI
                                    ?.exampleRequest
                                }
                              ></JSONPretty>
                            )}
                          </code>
                        </pre>
                        <div className="mtr-heading-New font-size-14">
                          Example Response:
                        </div>
                        <br />
                        <pre className="req NewOverFlowScroll">
                          <i
                            class="fa fa-clone float-right copy"
                            onClick={() =>
                              this.copydata(
                                this.state.data?.transactComplyAPI
                                  ?.exampleResponse
                              )
                            }
                            aria-hidden="true"
                          ></i>
                          <code>
                            {this.state.data?.transactComplyAPI
                              ?.exampleResponse && (
                              <JSONPretty
                                id="json-pretty"
                                data={
                                  this.state.data?.transactComplyAPI
                                    ?.exampleResponse
                                }
                              ></JSONPretty>
                            )}
                          </code>
                        </pre>
                      </div>
                      {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                    </div>
                  </section>
                  <section className="section-pb-50" id="data-fields">
                    <Apitablecomponent
                      transactionTable={
                        this.state.data?.transactComplyAPI?.transactionTable
                      }
                      entityTable={
                        this.state.data?.transactComplyAPI?.entityTable
                      }
                    />
                  </section>
                  <section className="section-pb-50" id="UpdateRuleAPI">
                    <div className="mtr-heading py-2 borderbottomtop mb-4">
                    Rule Configuration API
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 px-4">
                        <div className="vi-info-p  ">
                          {/* <p>Prescreening API provided risk score for various like PEP, Sanctions, AML etc within seconds to third party
                             platform via secured, stable, and highly available API. All the above checks are configurable for third
                             party platforms. </p> */}
                          <div className="my-3 apiborderbox col-lg-12 col-md-12 p-3">
                            <p>
                              <strong>Base URL</strong>:{" "}
                              {process.env.REACT_APP_BACKEND_API_BASE_URL}{" "}
                              <br />
                              <br />
                              <strong>End point</strong> :{" "}
                              {
                                this.state.data?.updateRuleBuilderJSON
                                  ?.updaRuleBuilderEndpoint
                              }{" "}
                              <br />
                              <br />
                              <strong>Method</strong>: POST <br />
                              <br />
                              <strong>Authentication</strong>: access token
                              recieved from authentication API
                            </p>
                          </div>
                        </div>
                        <div className="mtr-heading-New font-size-14">
                          Example Request
                        </div>
                        <br />
                        <pre className="req NewOverFlowScroll">
                          <i
                            class="fa fa-clone float-right copy"
                            onClick={() =>
                              this.copydata(
                                this.state.data?.updateRuleBuilderJSON
                                  ?.updaRuleBuilderInputJSON
                              )
                            }
                            aria-hidden="true"
                          ></i>
                          <code>
                            {this.state.data?.updateRuleBuilderJSON
                              ?.updaRuleBuilderInputJSON && (
                              <JSONPretty
                                id="json-pretty"
                                //   className="req2 NewOverFlowScroll"
                                data={
                                  this.state.data?.updateRuleBuilderJSON
                                    ?.updaRuleBuilderInputJSON
                                }
                              ></JSONPretty>
                            )}
                          </code>
                        </pre>
                        <div className="mtr-heading-New font-size-14">
                          Example Response:
                        </div>
                        <br />
                        <pre className="req NewOverFlowScroll">
                          <i
                            class="fa fa-clone float-right copy"
                            onClick={() =>
                              this.copydata(
                                this.state.data?.updateRuleBuilderJSON
                                  ?.updaRuleBuilderOutputJSON
                              )
                            }
                            aria-hidden="true"
                          ></i>
                          <code>
                            {this.state.data?.updateRuleBuilderJSON
                              ?.updaRuleBuilderOutputJSON && (
                              <JSONPretty
                                id="json-pretty"
                                data={
                                  this.state.data?.updateRuleBuilderJSON
                                    ?.updaRuleBuilderOutputJSON
                                }
                              ></JSONPretty>
                            )}
                          </code>
                        </pre>
                      </div>
                      {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                    </div>
                    <div className="vi-info-p">
                      <div className="row ">
                        <div className="col-lg-12 col-md-12 px-4">
                          <div className="subHeading font-size-16 pb-18">
                            Data Field - Rule Builder JSON
                          </div>
                          <div className="table-responsive react-bootstrap-table card">
                            <table className="table align-middle table-nowrap table-hover bg-white   NewTable ">
                              <thead>
                                <tr className="align-middle customBG">
                                  <th className="width2">Datafield Name </th>
                                  <th className="width2">Type</th>
                                  <th className="width2">Required</th>
                                  <th className="width2">Length</th>
                                  <th className="width2">Single</th>
                                  <th className="width2">Multiple</th>
                                  <th className="width">Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data?.updateRuleBuilderJSON
                                  ?.updateRuleBuilderTable !== undefined &&
                                  this.state.data?.updateRuleBuilderJSON
                                    ?.updateRuleBuilderTable !== 0 &&
                                  this.state.data?.updateRuleBuilderJSON?.updateRuleBuilderTable.map(
                                    (x, i) => (
                                      <tr key={i}>
                                        <td>{x.datafieldName}</td>
                                        <td>{x.type}</td>
                                        <td>{x.required}</td>
                                        <td>{x.length}</td>
                                        <td>{x.single}</td>
                                        <td>{x.multiple}</td>
                                        <td>{x.description}</td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="section-pb-50" id="UpdateRuleStatus">
                    <div className="mtr-heading py-2 borderbottomtop mb-4">
                    Rule Status API
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 px-4">
                        <div className="vi-info-p  ">
                          {/* <p>Prescreening API provided risk score for various like PEP, Sanctions, AML etc within seconds to third party
                             platform via secured, stable, and highly available API. All the above checks are configurable for third
                             party platforms. </p> */}
                          <div className="my-3 apiborderbox col-lg-12 col-md-12 p-3">
                            <p>
                              <strong>Base URL</strong>:{" "}
                              {process.env.REACT_APP_BACKEND_API_BASE_URL}{" "}
                              <br />
                              <br />
                              <strong>End point</strong> :{" "}
                              {
                                this.state.data?.updateRuleStatus
                                  ?.updateRuleStatusEndpoint
                              }{" "}
                              <br />
                              <br />
                              <strong>Method</strong>: PUT <br />
                              <br />
                              <strong>Authentication</strong>: access token
                              recieved from authentication API
                            </p>
                          </div>
                        </div>
                        <div className="subHeading font-size-16 pb-18 mt-5">
                          Parameters{" "}
                        </div>
                        <div className="table-responsive react-bootstrap-table card">
                          <table className="table align-middle table-nowrap table-hover  NewTable ">
                            <thead>
                              <tr className="align-middle customBG">
                                <th scope="col" className="width2">
                                  Datafield Name{" "}
                                </th>
                                <th scope="col" className="width2">
                                  Type
                                </th>
                                <th scope="col" className="width2">
                                  Required
                                </th>
                                <th scope="col" className="width2">
                                  Length
                                </th>
                                <th scope="col" className="width2">
                                  Single
                                </th>
                                <th scope="col" className="width2">
                                  Multiple
                                </th>
                                <th scope="col" className="width2">
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data?.updateRuleStatus
                                ?.updateRuleStatusInputTable !== undefined &&
                                this.state.data?.updateRuleStatus
                                  ?.updateRuleStatusInputTable !== 0 &&
                                this.state.data?.updateRuleStatus?.updateRuleStatusInputTable.map(
                                  (x, i) => (
                                    <tr key={i}>
                                      <td>{x.datafieldName}</td>
                                      <td>{x.type}</td>
                                      <td>{x.required}</td>
                                      <td>{x.length}</td>
                                      <td>{x.single}</td>
                                      <td>{x.multiple}</td>
                                      <td>{x.description}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                        <div className="mtr-heading-New font-size-14">
                          Example Response:
                        </div>
                        <br />
                        <pre className="req NewOverFlowScroll">
                          <i
                            class="fa fa-clone float-right copy"
                            onClick={() =>
                              this.copydata(
                                this.state.data?.updateRuleStatus
                                  ?.updateRuleStatusOutputJSON
                              )
                            }
                            aria-hidden="true"
                          ></i>
                          <code>
                            {this.state.data?.updateRuleStatus
                              ?.updateRuleStatusOutputJSON && (
                              <JSONPretty
                                id="json-pretty"
                                data={
                                  this.state.data?.updateRuleStatus
                                    ?.updateRuleStatusOutputJSON
                                }
                              ></JSONPretty>
                            )}
                          </code>
                        </pre>
                      </div>
                      {/* <div className='col-lg-6 col-md-12 ' >
                              <ApiMultiLanguageComponent json={JSON.stringify(screeningAPIResMsg, null, 2)} python={pythonResAuth} />
                           </div> */}
                    </div>
                  </section>
                  <section className="section-pb-50" id="Response-codes">
                    <div className="mtr-heading  py-2 borderbottomtop mb-4">
                      {" "}
                      HTTP Response Code Description{" "}
                    </div>
                    <div className="row">
                      <div className="col-md-12 px-4">
                        <div className="table-responsive react-bootstrap-table card">
                          <table className="table align-middle table-nowrap table-hover  NewTable ">
                            <thead>
                              <tr className="align-middle customBG">
                                <th scope="col" className="width2">
                                  Code{" "}
                                </th>
                                <th scope="col" className="width2">
                                  Response
                                </th>
                                <th scope="col" className="width2">
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>401</td>
                                <td>Error: response status is 401</td>
                                <td>The token expired</td>
                              </tr>
                              <tr>
                                <td>400</td>
                                <td>Bad request</td>
                                <td>
                                  Invalid Type. Type can only be either
                                  Individual, Organization, Vessels or All.
                                </td>
                              </tr>
                              <tr>
                                <td>408</td>
                                <td>Request Timeout Error</td>
                                <td>
                                  The server did not receive a complete request
                                  from the client within the server's allotted
                                  timeout period.
                                </td>
                              </tr>
                              <tr>
                                <td>409</td>
                                <td>Too Many Requests</td>
                                <td>The user has sent too many requests</td>
                              </tr>
                              <tr>
                                <td>500</td>
                                <td>Internal Server Error</td>
                                <td>
                                  The server has encountered a situation that it
                                  does not know how to handle
                                </td>
                              </tr>
                              <tr>
                                <td>504</td>
                                <td>Gateway Timeout</td>
                                <td>
                                  The server is acting as a gateway and cannot
                                  get a response in time for a request.
                                </td>
                              </tr>
                              <tr>
                                <td>200</td>
                                <td>Ok</td>
                                <td>Request recieved</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>{" "}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/* end blog content section */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default apidoc;
