export const authresponse =
{
"access_token": "*******************************",
"expire": "30 minute's"
}
export const screeningAPIReqMsg ={
    "projectId": 4,
    "startDate": "string",
    "endDate": "string",
    "entityName": "Aung Pang Sone",
    "industry": "string",
    "panNumber": null,
    "PassportNumber": [],
    "emails": [
      "abc@gmail.com"
    ],
    "phoneNumbers": [
      "+911234567890"
    ],
    "reportType": "Detailed",
    "organization": [],
    "identifiers": [],
    "designation": null,
    "type": "Individual",
    "vesselsType": null,
    "sector": null,
    "country": [
      "Myanmar"
    ],
    "gender": null,
    "dob": "02-11-2000",
    "cin": null,
    "din": null,
    "state": null,
    "city": null,
    "aliases": [],
    "associations": [],
    "nationality": "string",
    "projectSetting": {
      "entityInputCheckModels": [
        {
          "checkName": "Indian Watchlists",
          "thresholdName": "Low",
          "thresholdvalue": 0,
          "thresholdStartRange": 0,
          "thresholdEndRange": 50,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "Indian Watchlists",
          "thresholdName": "Medium",
          "thresholdvalue": 1,
          "thresholdStartRange": 51,
          "thresholdEndRange": 90,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "Indian Watchlists",
          "thresholdName": "High",
          "thresholdvalue": 2,
          "thresholdStartRange": 91,
          "thresholdEndRange": 100,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "Indonesia Watchlists",
          "thresholdName": "Low",
          "thresholdvalue": 0,
          "thresholdStartRange": 0,
          "thresholdEndRange": 50,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "Indonesia Watchlists",
          "thresholdName": "Medium",
          "thresholdvalue": 1,
          "thresholdStartRange": 51,
          "thresholdEndRange": 90,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "Indonesia Watchlists",
          "thresholdName": "High",
          "thresholdvalue": 2,
          "thresholdStartRange": 91,
          "thresholdEndRange": 100,
          "checksWeightage": 0.5,
          "listName": [],
          "listUId": [],
          "maxScore": 2
        },
        {
          "checkName": "UANI Check",
          "thresholdName": "Low",
          "thresholdvalue": 0,
          "thresholdStartRange": 0,
          "thresholdEndRange": 50,
          "checksWeightage": 0.5,
          "maxScore": 2
        },
        {
          "checkName": "UANI Check",
          "thresholdName": "Medium",
          "thresholdvalue": 1,
          "thresholdStartRange": 51,
          "thresholdEndRange": 90,
          "checksWeightage": 0.5,
          "maxScore": 2
        },
        {
          "checkName": "UANI Check",
          "thresholdName": "High",
          "thresholdvalue": 2,
          "thresholdStartRange": 91,
          "thresholdEndRange": 100,
          "checksWeightage": 0.5,
          "maxScore": 2
        }
      ],
      "compositeScoreAlgoName": "max",
      "entityScoreAlgoName": "max",
      "checkResultCount": {
        "Indian Watchlists": 3,
        "Indonesia Watchlists": 3,
        "UANI Check": 3
      },
      "caseScoreMapping": [
        {
          "status": "Green",
          "startRange": 0,
          "endRange": 1
        },
        {
          "status": "Amber",
          "startRange": 2,
          "endRange": 7
        },
        {
          "status": "Red",
          "startRange": 8,
          "endRange": 10
        }
      ],
      "entityScoreMapping": [
        {
          "status": "Green",
          "startRange": 0,
          "endRange": 1
        },
        {
          "status": "Amber",
          "startRange": 2,
          "endRange": 7
        },
        {
          "status": "Red",
          "startRange": 8,
          "endRange": 10
        }
      ]
    }
  }
export const screeningAPIResMsg = {
    "pdfUrl": "https://datafacade-bucket.s3.amazonaws.com/CL103287_Detailed.pdf",
    "entitychecks": [
      {
        "entityName": "Aung Pang Sone",
        "entityType": "Individual",
        "totalResponseCount": 3,
        "UANI Check": [
          {
            "Date Sanctioned by Canada": "",
            "Match Score": "",
            "Full Name": "",
            "Alias": "",
            "Address": "",
            "Citizenship": "",
            "Email Match Status": "",
            "Email": "",
            "Email Match Details": "",
            "Phone Number Match Status": "",
            "Phone Number": "",
            "Phone Number Match Details": "",
            "List Name": "",
            "Sources": "",
            "Website": "",
            "Name of Associated Subject": "",
            "Sanctioned by United States": "",
            "Date Sanctioned by United States": "",
            "United States Laws and Executive Orders Sanctioned Under": "",
            "Sanctioned by European Union": "",
            "Date Sanctioned by European Union": "",
            "European Union Laws and Executive Orders Sanctioned Under": "",
            "Sanctioned by United Kingdom": "",
            "Date Sanctioned by United Kingdom": "",
            "United Kingdom Laws and Executive Orders Sanctioned Under": "",
            "Sanctioned by Canada": "",
            "details": "No Hits Found",
            "Canada Laws and Executive Orders Sanctioned Under": "",
            "Sanctioned by Australia": "",
            "Date Sanctioned by Australia": "",
            "Australia Laws and Executive Orders Sanctioned Under": "",
            "Penalty Amount": "",
            "Imprisonment": "",
            "Authority": "",
            "InputType": "Individual",
            "InputEntityName": "Aung Pang Sone",
            "InputAlias": [],
            "InputIdentifier_4": [],
            "InputDesignation": "",
            "InputAssociations": [],
            "InputAddress": "",
            "InputState": "",
            "InputCountry": [
              "Myanmar"
            ],
            "InputCity": "",
            "InputCin": "",
            "InputDin": "",
            "InputEmailID": [
              "abc@gmail.com"
            ],
            "InputPhoneNumber": [
              "+911234567890"
            ],
            "InputSector": "",
            "InputIndustry": "string",
            "InputOrganization": [],
            "InputDateofBirth": "02 Nov 2000",
            "InputPan": "",
            "InputGender": "",
            "InputNationality": "string",
            "InputVesselsType": "",
            "timestamp": "22 Sep 2023, GMT 06:32:45",
            "match_status": "Green",
            "match_score": "0",
            "Comment": "",
            "Match": "",
            "FinalStatus": "",
            "FinalScore": "",
            "FinalTimeStamp": "",
            "Reviewer": "",
            "Label": "",
            "RunId": 2699,
            "mappingId": 1,
            "Attachment": "",
            "fileName": ""
          }
        ],
        "HitsFound": {
          "UANI Check": 0,
          "Indian Watchlists": 3,
          "Indonesia Watchlists": 0
        },
        "Indian Watchlists": [
          {
            "Position": "",
            "DIN": "",
            "Reason": "",
            "Country": "Myanmar",
            "Authority Name": "Wildlife Crime Control Bureau (WCCB)",
            "ReasonofSuspension": "",
            "DOB": "",
            "DefaulterExpelledSuspensiondate": "",
            "Charges": "",
            "Age": 28,
            "DobScore": "0%",
            "Current Status": "",
            "PenaltyAmount": "10,000",
            "fuzzy_score": "83%",
            "Reward": "",
            "ListName": "Wildlife Crime Control Bureau (WCCB) - Convicts",
            "Status": "",
            "Address": "Myanmar",
            "SourceLink": "http://wccb.gov.in/Content/Convicts.aspx",
            "details": "Aung Aung Thin Htun",
            "SourceName": "Wildlife Crime Control Bureau (WCCB)",
            "MatchedPanNo": "",
            "Nationality": "",
            "Image Url": "",
            "Name": "Aung Aung Thin Htun",
            "DistinguishingMarks": "",
            "Alias": "",
            "DIN_match_details_ds": "",
            "NameofAssociatedCompany": "",
            "DobMatchDetails": "",
            "Gender": "",
            "PAN match status": "",
            "Email Match Status": "Not Available",
            "Email": "",
            "Email Match Details": [],
            "Phone Number": "",
            "Phone Number Match Status": "Not Available",
            "Phone Number Match Details": [],
            "DIN_match_status_ds": "",
            "Branch": "",
            "Outstanding Amount": "",
            "Lender Bank": "",
            "Court Name": "",
            "Quarter": "",
            "CourtJudgement": "",
            "CaseName": "",
            "CaseNumber": "183/2013 ",
            "Section": "Section 50/51 Wild Life (P) Act",
            "Appeal Number": "",
            "Date of Appeal": "",
            "Notice Date": "",
            "Suit": "",
            "SEBI Reference Number": "",
            "Vendor Identification Number": "",
            "Debarred Date From": "",
            "Debarred Date To": "",
            "SEBI Registration Number": "",
            "Complaint Number": "",
            "Registration Number": "",
            "Violation": "",
            "Forward Markets Commission (FMC) Registration Number": "",
            "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
            "Outcome": "",
            "Purpose": "",
            "Name of Respondent": "",
            "Defendant Name": "",
            "Account Name": "",
            "Nature of Offence": "",
            "Imprisonment Term": "",
            "Government Notification Number": "",
            "Date of Offence": "",
            "Absconding Status": "",
            "Absconding Date": "",
            "Action Taken": "",
            "timestamp": "22 Sep 2023, GMT 06:32:46",
            "match_score": 1,
            "match_status": "Amber",
            "Comment": "",
            "Match": "",
            "FinalStatus": "",
            "FinalScore": "",
            "FinalTimeStamp": "",
            "Reviewer": "",
            "Label": "",
            "RunId": 2699,
            "Attachment": "",
            "fileName": "",
            "mappingId": 1,
            "InputType": "Individual",
            "InputEntityName": "Aung Pang Sone",
            "InputAlias": [],
            "InputIdentifier_4": [],
            "InputDesignation": "",
            "InputAssociations": [],
            "InputAddress": "",
            "InputState": "",
            "InputCountry": [
              "Myanmar"
            ],
            "InputCity": "",
            "InputCin": "",
            "InputDin": "",
            "InputEmailID": [
              "abc@gmail.com"
            ],
            "InputPhoneNumber": [
              "+911234567890"
            ],
            "InputSector": "",
            "InputIndustry": "string",
            "InputOrganization": [],
            "InputDateofBirth": "02 Nov 2000",
            "InputPan": "",
            "InputGender": "",
            "InputNationality": "string",
            "InputVesselsType": ""
          },
          {
            "Position": "",
            "DIN": "",
            "Reason": "",
            "Country": "Myanmar",
            "Authority Name": "Wildlife Crime Control Bureau (WCCB)",
            "ReasonofSuspension": "",
            "DOB": "",
            "DefaulterExpelledSuspensiondate": "",
            "Charges": "",
            "Age": "",
            "DobScore": "0%",
            "Current Status": "",
            "PenaltyAmount": "10,000",
            "fuzzy_score": "67%",
            "Reward": "",
            "ListName": "Wildlife Crime Control Bureau (WCCB) - Convicts",
            "Status": "",
            "Address": "Myanmar",
            "SourceLink": "http://wccb.gov.in/Content/Convicts.aspx",
            "details": "Aung Koko Ming",
            "SourceName": "Wildlife Crime Control Bureau (WCCB)",
            "MatchedPanNo": "",
            "Nationality": "",
            "Image Url": "",
            "Name": "Aung Koko Ming",
            "DistinguishingMarks": "",
            "Alias": "Ko Lak",
            "DIN_match_details_ds": "",
            "NameofAssociatedCompany": "",
            "DobMatchDetails": "",
            "Gender": "",
            "PAN match status": "",
            "Email Match Status": "Not Available",
            "Email": "",
            "Email Match Details": [],
            "Phone Number": "",
            "Phone Number Match Status": "Not Available",
            "Phone Number Match Details": [],
            "DIN_match_status_ds": "",
            "Branch": "",
            "Outstanding Amount": "",
            "Lender Bank": "",
            "Court Name": "Court of Addl. District & Session Judge",
            "Quarter": "",
            "CourtJudgement": "",
            "CaseName": "",
            "CaseNumber": " 203/2013 ",
            "Section": "Section 50/51 Wild Life (P) Act",
            "Appeal Number": "",
            "Date of Appeal": "",
            "Notice Date": "",
            "Suit": "",
            "SEBI Reference Number": "",
            "Vendor Identification Number": "",
            "Debarred Date From": "",
            "Debarred Date To": "",
            "SEBI Registration Number": "",
            "Complaint Number": "",
            "Registration Number": "",
            "Violation": "",
            "Forward Markets Commission (FMC) Registration Number": "",
            "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
            "Outcome": "",
            "Purpose": "",
            "Name of Respondent": "",
            "Defendant Name": "",
            "Account Name": "",
            "Nature of Offence": "",
            "Imprisonment Term": "",
            "Government Notification Number": "",
            "Date of Offence": "",
            "Absconding Status": "",
            "Absconding Date": "",
            "Action Taken": "",
            "timestamp": "22 Sep 2023, GMT 06:32:46",
            "match_score": 1,
            "match_status": "Amber",
            "Comment": "",
            "Match": "",
            "FinalStatus": "",
            "FinalScore": "",
            "FinalTimeStamp": "",
            "Reviewer": "",
            "Label": "",
            "RunId": 2699,
            "Attachment": "",
            "fileName": "",
            "mappingId": 2,
            "InputType": "Individual",
            "InputEntityName": "Aung Pang Sone",
            "InputAlias": [],
            "InputIdentifier_4": [],
            "InputDesignation": "",
            "InputAssociations": [],
            "InputAddress": "",
            "InputState": "",
            "InputCountry": [
              "Myanmar"
            ],
            "InputCity": "",
            "InputCin": "",
            "InputDin": "",
            "InputEmailID": [
              "abc@gmail.com"
            ],
            "InputPhoneNumber": [
              "+911234567890"
            ],
            "InputSector": "",
            "InputIndustry": "string",
            "InputOrganization": [],
            "InputDateofBirth": "02 Nov 2000",
            "InputPan": "",
            "InputGender": "",
            "InputNationality": "string",
            "InputVesselsType": "",
            "InputCountryOfResidence": [],
            "InputPassport": [],
          },
          {
            "Rule Applied": null,
            "Priority": null,
            "Position": "Director",
            "DIN": 2493747,
            "Reason": "",
            "Country": "",
            "Authority Name": "Ministry of Corporate Affairs (MCA)",
            "ReasonofSuspension": "Violated section 164(2)(a)",
            "DOB": "",
            "DefaulterExpelledSuspensiondate": "",
            "Charges": "",
            "Age": "",
            "DobScore": "0%",
            "Current Status": "Disqualified  u/s 164(2)(a)",
            "PenaltyAmount": "",
            "fuzzy_score": "67%",
            "Reward": "",
            "ListName": "Ministry of Corporate Affairs  (MCA) - List of Disqualified Directors U/S 164 (2)(A)",
            "Status": "Disqualified  u/s 164(2)(a)",
            "Address": "",
            "SourceLink": "https://www.mca.gov.in/content/mca/global/en/data-and-reports/rd-roc-info/disqualified-directors.html",
            "details": "Pushpa Pangtey",
            "SourceName": "Ministry of Corporate Affairs Website",
            "MatchedPanNo": "",
            "Nationality": "",
            "Image Url": "",
            "Name": "Pushpa Pangtey",
            "DistinguishingMarks": "",
            "Alias": "",
            "DIN_match_details_ds": "",
            "NameofAssociatedCompany": "Mountain Expedition Private Limited",
            "DobMatchDetails": "",
            "Gender": "",
            "PAN match status": "",
            "Email Match Status": "Not Available",
            "Email": "",
            "Email Match Details": [],
            "Phone Number": "",
            "Phone Number Match Status": "Not Available",
            "Phone Number Match Details": [],
            "DIN_match_status_ds": "",
            "Branch": "",
            "Outstanding Amount": "",
            "Lender Bank": "",
            "Court Name": "",
            "Quarter": "",
            "CourtJudgement": "",
            "CaseName": "",
            "CaseNumber": "",
            "Section": "",
            "Appeal Number": "",
            "Date of Appeal": "",
            "Notice Date": "",
            "Suit": "",
            "SEBI Reference Number": "",
            "Vendor Identification Number": "",
            "Debarred Date From": "",
            "Debarred Date To": "",
            "SEBI Registration Number": "",
            "Complaint Number": "",
            "Registration Number": "",
            "Violation": "",
            "Forward Markets Commission (FMC) Registration Number": "",
            "Institute of Chartered Accountants of India (ICAI) Membership Number": "",
            "Outcome": "",
            "Purpose": "",
            "Name of Respondent": "",
            "Defendant Name": "",
            "Account Name": "",
            "Nature of Offence": "",
            "Imprisonment Term": "",
            "Government Notification Number": "",
            "Date of Offence": "",
            "Absconding Status": "",
            "Absconding Date": "",
            "Action Taken": "",
            "timestamp": "22 Sep 2023, GMT 06:32:46",
            "match_score": 1,
            "match_status": "Amber",
            "Comment": "",
            "Match": "",
            "FinalStatus": "",
            "FinalScore": "",
            "FinalTimeStamp": "",
            "Reviewer": "",
            "Label": "",
            "RunId": 2699,
            "Attachment": "",
            "fileName": "",
            "mappingId": 3,
            "InputType": "Individual",
            "InputEntityName": "Aung Pang Sone",
            "InputAlias": [],
            "InputIdentifier_4": [],
            "InputDesignation": "",
            "InputAssociations": [],
            "InputAddress": "",
            "InputState": "",
            "InputCountry": [
              "Myanmar"
            ],
            "InputCity": "",
            "InputCin": "",
            "InputDin": "",
            "InputEmailID": [
              "abc@gmail.com"
            ],
            "InputPhoneNumber": [
              "+911234567890"
            ],
            "InputSector": "",
            "InputIndustry": "string",
            "InputOrganization": [],
            "InputDateofBirth": "02 Nov 2000",
            "InputPan": "",
            "InputGender": "",
            "InputNationality": "string",
            "InputVesselsType": ""
          }
        ],
        "Indonesia Watchlists": [
          {
            "Penalty Amount": "",
            "Match Score": "",
            "Full Name": "",
            "Alias": "",
            "Address": "",
            "Email Match Status": "",
            "Email": "",
            "Email Match Details": "",
            "Phone Number Match Status": "",
            "Phone Number": "",
            "Phone Number Match Details": "",
            "List Name": "",
            "Sources": "",
            "Website": "",
            "Current Status": "",
            "Reason": "",
            "details": "No Hits Found",
            "Date of Publish": "",
            "Authority": "",
            "InputType": "Individual",
            "InputEntityName": "Aung Pang Sone",
            "InputAlias": [],
            "InputIdentifier_4": [],
            "InputDesignation": "",
            "InputAssociations": [],
            "InputAddress": "",
            "InputState": "",
            "InputCountry": [
              "Myanmar"
            ],
            "InputCity": "",
            "InputCin": "",
            "InputDin": "",
            "InputEmailID": [
              "abc@gmail.com"
            ],
            "InputPhoneNumber": [
              "+911234567890"
            ],
            "InputSector": "",
            "InputIndustry": "string",
            "InputOrganization": [],
            "InputDateofBirth": "02 Nov 2000",
            "InputPan": "",
            "InputGender": "",
            "InputNationality": "string",
            "InputVesselsType": "",
            "timestamp": "22 Sep 2023, GMT 06:32:45",
            "match_status": "Green",
            "match_score": "0",
            "Comment": "",
            "Match": "",
            "FinalStatus": "",
            "FinalScore": "",
            "FinalTimeStamp": "",
            "Reviewer": "",
            "Label": "",
            "RunId": 2699,
            "mappingId": 1,
            "Attachment": "",
            "fileName": ""
          }
        ]
      }
    ],
    "Case_Outcome": {
      "Name": "Aung Pang Sone",
      "CaseId": "CL103287",
      "CompositeApplicationStatus": "Need Review",
      "Score": "5/10",
      "Status": "Amber",
      "Uid": "7a5c3880-d91d-4406-a01d-bae5d2a4a156",
      "EntityName": "Aung Pang Sone"
    },
    "CaseAssociation": [
      {
        "Name": "Aung Pang Sone",
        "Score": "5/10",
        "Status": "Amber",
        "Type": "Individual",
        "Uid": "a21851be-9748-40b9-804b-ab5f21afb5e9"
      }
    ],
    "Subscribed": {},
    "ListSubscribed": {
      "message": "You have not subscribed to these list(s)",
      "body": {}
    },
    "NotExistsList": {
      "message": "list(s) not exists",
      "body": {}
    }
  }
   
export const pythonResAuth = `
import http.client
conn = http.client.HTTPSConnection("clientdevapiv2.amlpenalties.com")
payload = ''
headers = {
'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NjI2MjQ5MjN9.6Z5H3YG7eEggheMuuJrjSP8BPyp96WjUiqPv_-l-ltI'
}
conn.request("GET", "/api/cms/add_blog_page_get_byname/CRYPTO-AML", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))

`
export const  DiffkindReportDownloadAPI={
    "pdfUrl": "https://datafacade-bucket.s3.amazonaws.com/CL95389_Summary.pdf",
    "message": "use this url to download your pdf."
}