import React from 'react'

const Apitablecomponent = (props) => {
    return (
        <div className="vi-info-p" >
            <div className="mtr-heading font-size-25 py-2 borderbottomtop mb-3">Data Field Details</div>
            <div className='row '>
            <div className='col-lg-12 col-md-12 px-4'>
            <div className="subHeading font-size-16 pb-18">Data Field - Transaction</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th className='width2' >Datafield Name </th>
                            <th className='width2'>Type</th>
                            <th className='width2'>Required</th>
                            <th className='width2'>Length</th>
                            <th className='width2'>Single</th>
                            <th className='width2'>Multiple</th>
                            <th className='width'>Description</th>
                        </tr>
                    </thead>
                  <tbody>
                    {
                   ( props?.transactionTable!==undefined && props?.transactionTable?.length!==0 ) && props?.transactionTable.map((x,i)=>(
                            <tr key={i}>
                              <td>{x.datafieldName}</td>
                              <td>{x.type}</td>
                              <td>{x.required}</td>
                              <td>{x.length}</td>
                              <td>{x.single}</td>
                              <td>{x.multiple}</td>
                              <td>{x.description}</td> 
                            </tr>
                        ))
                    }
                  </tbody>
                </table>
           
            </div>
            <div className="subHeading font-size-16 pb-18">Data Field - Entity</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th className='width2' >Datafield Name </th>
                            <th className='width2'>Type</th>
                            <th className='width2'>Required</th>
                            <th className='width2'>Length</th>
                            <th className='width2'>Single</th>
                            <th className='width2'>Multiple</th>
                            <th className='width'>Description</th>
                        </tr>
                    </thead>
                  <tbody>
                    {
                    ( props?.entityTable!==undefined && props?.entityTable?.length!==0 )&&   props?.entityTable.map((x,i)=>(
                            <tr key={i}>
                              <td>{x.datafieldName}</td>
                              <td>{x.type}</td>
                              <td>{x.required}</td>
                              <td>{x.length}</td>
                              <td>{x.single}</td>
                              <td>{x.multiple}</td>
                              <td>{x.description}</td> 
                            </tr>
                        ))
                    }
                  </tbody>
                </table>
           
            </div>
            {/* <div className='table-responsive react-bootstrap-table card'>
            <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th className='width2' >Datafield Name </th>
                            <th className='width2'>Type</th>
                            <th className='width2'>Required</th>
                            <th className='width2'>Length</th>
                            <th className='width2'>Single</th>
                            <th className='width2'>Multiple</th>
                            <th className='width2'>Description</th>
                        </tr>
                        </thead>
                    <tbody>
                    <tr>
                            <td>reportType</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Can only have these values:  Summary, Hits Only Report, Detailed</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Data Fields – Associated Entity </div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>AssociationsName </td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td rowSpan={15} className='p-5 commonRow align-middle'>These are the
                                Associations Detail
                                fields. (Json Field
                                Name:-
                                Associations) Note
                                Associations can be multiple</td>
                        </tr>
                        <tr>
                            <td>AssociationsCountry</td>
                            <td>Array of strings</td>
                            <td>No</td>
                            <td>3</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsType</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>50</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsIndustry</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsOrganization</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>AssociationVesselsType</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsIndentifiers</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>AssociationsSector</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsAliases</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>AssociationsDesignation</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td></td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>AssociationsCity</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsGender</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationsDob</td>
                            <td>date</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>AssociationPanNumber</td>
                            <td>string</td>
                            <td>No</td>
                            <td>10</td>
                            <td>Yes</td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>AssociationsState</td>
                            <td>string</td>
                            <td>No</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Data Fields – Entity Input Check Models (Project Settings) </div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>checkName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: PepCheck,SanctionCheck, AmlCheck, Indian Watchlists</td>
                        </tr>
                        <tr>
                            <td>thresholdName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: Low, Medium, High</td>
                        </tr>
                        <tr>
                            <td>thresholdvalue</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td>50</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: 0, 1, 2</td>
                        </tr>
                        <tr>
                            <td>thresholdStartRange</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value greater than or equal to 0 and up to 100</td>
                        </tr>
                        <tr>
                            <td>thresholdEndRange</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 100</td>
                        </tr>
                        <tr>
                            <td>checksWeightage</td>
                            <td>float</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 1.0</td>
                        </tr>
                        <tr>
                            <td>maxScore</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value up to 2</td>
                        </tr>
                        <tr>
                            <td>ListName</td>
                            <td>string</td>
                            <td>No</td>
                            <td></td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>This input will guarantee that your search will take this list into account.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Data Fields – Score Status Mappings </div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>score</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have a value ranging between 0 and 10 </td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>projectId</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This value is assigned by Prescreening's team</td>
                        </tr>
                        <tr>
                            <td>actions</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)
                            </td>
                            <td>Yes</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Data Fields – Algorithm Settings</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>compositeScoreAlgoName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: max, sum, average</td>
                        </tr>
                        <tr>
                            <td>entityScoreAlgoName</td>
                            <td>string</td>
                            <td>Yes</td>
                            <td>nvarchar(max)</td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can only have these values: max, sum, weighted_average</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Data Fields – Check Result Count</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Required</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                            <th scope="col" className='width2'>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PepCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>SanctionCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>AmlCheck</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                        <tr>
                            <td>Indian Watchlists</td>
                            <td>int</td>
                            <td>Yes</td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                            <td>This field can have n number of values.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='subHeading font-size-16 pb-18 mt-5'>Case Outcome</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CaseName</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CaseId</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CompositeApplicationStatus</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Uid</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>EntityName</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='subHeading font-size-16 pb-18 mt-5'>Case Associations (Multiple)</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>UpdatedStatus</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>ListingId</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>RoleId</td>
                        <td>string</td>
                        <td>int32</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Uid</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>isUpdated</td>
                        <td>booolean</td>
                        <td></td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='subHeading font-size-16 pb-18 mt-5'>PEPs (Multiple)</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>full_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_description</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>per_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Alias</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='subHeading font-size-16 pb-18 mt-5'>Sanctions (Multiple)</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>full_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_name</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>fuzzy_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>sanction_date</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_description</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Alias</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Program</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>

            <div className='subHeading font-size-16 pb-18 mt-5'>AML Penalties (Single)</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                        <th scope="col" className='width3' >Data Field</th>
                        <th scope="col" className='width3'>Type</th>
                        <th scope="col" className='width3'>Length</th>
                        <th scope="col" className='width3'>Single</th>
                        <th scope="col" className=''>Multiple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>match_status</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Details</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Timestamp</td>
                        <td>string</td>
                        <td>100</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>match_name</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>fuzzy_score</td>
                        <td>string</td>
                        <td>50</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>AmlCategory</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>event_link</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Keywords</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Entities</td>
                        <td>string</td>
                        <td>max</td>
                        <td>Yes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table></div>
            <div className='subHeading font-size-16 pb-18 mt-5'>PDF</div>
            <div className='table-responsive react-bootstrap-table card'>
                <table className="table align-middle table-nowrap table-hover bg-white   NewTable " >
                    <thead>
                        <tr className='align-middle customBG'>
                            <th scope="col" className='width2' >Datafield Name </th>
                            <th scope="col" className='width2'>Type</th>
                            <th scope="col" className='width2'>Length</th>
                            <th scope="col" className='width2'>Single</th>
                            <th scope="col" className='width2'>Multiple</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>PdfUrl</td>
                            <td>string</td>
                            <td>max</td>
                            <td>Yes</td>
                            <td></td>
                            
                        </tr>
                    </tbody>
                </table>
            </div> */}
            </div>
            </div>
          


        </div>
    )
}

export default Apitablecomponent